

// TODO: should get from TMDB API
const W342H513 = Object.freeze({
  WIDTH: 342,
  HEIGHT: 513
});

const W780H1170 = Object.freeze({
  WIDTH: 780,
  HEIGHT: 1170
});

const W185H278 = Object.freeze({
  WIDTH: 185,
  HEIGHT: 278
});

const W500H282 = Object.freeze({
  WIDTH: 500,
  HEIGHT: 282
});

const W92H138 = Object.freeze({
  WIDTH: 92,
  HEIGHT: 138
});

const W355_AND_H200_BESTV2 = Object.freeze({
  FULL: 'w355_and_h200_bestv2',
  WIDTH: 355,
  HEIGHT: 200
});
;
const W710_AND_H400_BESTV2 = Object.freeze({
  FULL: 'w700_and_h400_bestv2',
  WIDTH: 700,
  HEIGHT: 400
});

export {
  W342H513,
  W780H1170,
  W185H278,
  W500H282,
  W92H138,
  W355_AND_H200_BESTV2,
  W710_AND_H400_BESTV2
};
